import React from 'react';
import {Box, Divider, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import TooltipedTitle from "../../../shared/components/TooltipedTitle";
import {convertBold, convertList} from "../../../../shared/utils";

type ListPlanDetail = {
  title: string,
  titleClassName?: string,
  tooltip?: string,
  content: (string | JSX.Element)[],
  thin?: boolean
}

export function ListPlanDetail({title, content, thin, tooltip, titleClassName}: ListPlanDetail) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"start"} my={"10px"}>
        <Grid item xs={6} className={'pl-20'}>
          {!tooltip && <Typography variant={"h5"} color={'textPrimary'}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <TooltipedTitle title={title} className={!thin ? `bold ${titleClassName ? titleClassName : ''}` : 'thin'} tooltip={tooltip} />}
        </Grid>
        <Grid item xs={6} className={'pr-20'}>
          <ListPlanDetailText content={content} className={phone ? 'ml-16' : undefined} />
        </Grid>
      </Box>
      <Divider/>
    </>
  )
}

export const ListPlanDetailText = ({content, className}: { content: (string | JSX.Element)[], className?: string }) => (
  <Box display={"flex"} flexGrow={2} flexDirection={"column"} className={className}>
    {( !content || content.length == 0 ) &&
    <Typography variant={"h5"} color={'textPrimary'}>Not covered</Typography>
    }
    {(!!content && content.length > 0 ) && content.map((line, i) =>
      <Box key={i} minHeight={'18px'}>
        <Typography className={'fs-12'} color={'textPrimary'}>
          {typeof line === 'string' && <span dangerouslySetInnerHTML={{__html: convertList(convertBold(line))}}></span>}
          {typeof line !== 'string' && <>{line}</>}
        </Typography>
      </Box>
    )}
  </Box>
)
