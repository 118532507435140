import React from "react";
import paths from "../config/router-paths";
import UpdateZipModal from "./components/UpdateZipModal";
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";
import {getQuoteId} from "../../../shared/QuoteId";
import {KeycloakContext} from "../../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../../Private/hooks/useUserProfile";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import {QuoteContext} from "./QuoteContext";
import { useHistory } from "react-router-dom";
import {keycloak, KeycloakRoles} from "../../../shared/keycloak";
import * as QueryString from "query-string";

export default function ZipSelection() {
  const [open, setOpen] = React.useState(false);
  const quote = React.useContext(QuoteContext);
  const history = useHistory();
  const [getProfile, {data: profileData}] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);
  const {clear} = React.useContext(ComparisonContext);
  const [getQuote, data] = useQuestionnaireRequest(res => {
    if (!!res?.personalDetails?.zip && !!res?.personalDetails?.countyName) {
      history.push(paths.plans + res?.personalDetails?.zip + '/' + res?.personalDetails?.countyName + document.location.search);
    } else {
      setOpen(true);
    }
  });

  const quoteId = React.useMemo(() => {
      if (quote) {
        return quote.id;
      }
      return getQuoteId()
  }, [quote]);

  React.useEffect(() => {
    const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN);
    if (authenticated && !isAdmin) {
      getProfile()
    } else {
      if (quoteId) {
        getQuote({variables: {id: quoteId}})
      } else {
        const params = QueryString.parse(document.location.search);
        if (params.adminQuote) {
          getQuote({variables: {id: params.adminQuote as string}});
        } else {
          setOpen(true);
        }
      }
    }
  }, [quoteId, authenticated])

  React.useEffect(() => {
    if (profileData?.userProfile) {
      if (profileData?.userProfile?.zip && profileData?.userProfile?.countyName) {
        clear();
        document.location.href = paths.plans + profileData.userProfile.zip + '/' + profileData?.userProfile?.countyName;
      } else if (profileData?.userProfile.quoteId) {
        getQuote({variables: {id: profileData?.userProfile.quoteId}})
      } else {
        setOpen(true);
      }
    }
  }, [profileData])

  return <>
      <UpdateZipModal title={'Enter your zip code'}
                      subtitle={'We will load plan results in your area.'}
                      onClose={(zip?: string, county?: string) => {
                        if (zip && county) {
                          document.location.href = paths.plans + zip + '/' + county;
                          setStoredUser({...getStoredUser(), countyName: county})
                        }
                      }}
                      open={open}
                      disableBackdropClick={true}
                      disableSidePadding={true}
                      buttonLabel={'Continue'} />
    </>;
}
