import React from "react";

import {useHistory} from "react-router";
import {MedigapQuoteContext} from "../shared/MedigapQuoteContext";
import {useUserProfileLazy} from "../../Private/hooks/useUserProfile";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {ComparisonContext} from "./PlanComparison/ComparisonContext";
import {getMedigapQuoteId, setMedigapQuoteId} from "../../shared/QuoteId";
import paths from "./config/router-paths";
import UpdateZipModal from "../shared/components/UpdateZipModal";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";
import * as QueryString from "query-string";
import {useMedigapFilterState} from "./PlanList/hooks/useMedigapFilterState";

export default function ZipSelection() {
  const [open, setOpen] = React.useState(false);
  const quote = React.useContext(MedigapQuoteContext);
  const [getProfile, {data: profileData}] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);
  const {clear} = React.useContext(ComparisonContext);
  const history = useHistory();
  const [getFilterState, filterStateData] = useMedigapFilterState();

  React.useEffect(() => {
      if (quote) {
        history.push(paths.plans + quote.medigapFilterState?.zip + '/' + quote.medigapFilterState?.county + document.location.search);
      } else if (!getMedigapQuoteId()) {
        const params = QueryString.parse(document.location.search);
        if (params.filters) {
          getFilterState({variables: {id: params.filters}});
        } else {
          setOpen(true);
        }
      }
  }, [quote]);

  React.useEffect(() => {
      if (filterStateData.data) {
        history.push(paths.plans + filterStateData.data?.medigapFilterState?.zip + '/' + filterStateData.data?.medigapFilterState?.county + document.location.search);
      }
  }, [filterStateData]);

  React.useEffect(() => {
    if (authenticated) {
      getProfile()
    }
  }, [authenticated])

  React.useEffect(() => {
    if (profileData?.userProfile) {
      if (profileData?.userProfile?.zip && profileData?.userProfile?.countyName) {
        clear();
        history.push(paths.plans + profileData.userProfile.zip + '/' + profileData?.userProfile?.countyName + document.location.search);
      } else if (profileData?.userProfile.quoteId) {
        setMedigapQuoteId(profileData?.userProfile.quoteId)
      } else {
        setOpen(true);
      }
    }
  }, [profileData])



  /*const [getQuote, data] = useMedigapQuote({
    onCompleted: res => {
      if (!!res?.medigapQuote?.medigapFilterState?.zip && !!res?.medigapQuote?.medigapFilterState?.county) {
        history.push(paths.plans + res?.medigapQuote?.medigapFilterState?.zip + '/' + res?.medigapQuote?.medigapFilterState?.county);
      } else {
        setOpen(true);
      }
    }
  });

  const quoteId = React.useMemo(() => {
      if (quote) {
        return quote.id;
      }
      return getMedigapQuoteId()
  }, [quote]);

  React.useEffect(() => {
    if (authenticated) {
      getProfile()
    } else {
      if (quoteId) {
        getQuote({variables: {id: quoteId}})
      } else {
        setOpen(true);
      }
    }
  }, [quoteId, authenticated])

  React.useEffect(() => {
    if (profileData?.userProfile) {
      if (profileData?.userProfile?.zip && profileData?.userProfile?.countyName) {
        clear();
        history.push(paths.category + profileData.userProfile.zip + '/' + profileData?.userProfile?.countyName);
      } else if (profileData?.userProfile.quoteId) {
        getQuote({variables: {id: profileData?.userProfile.quoteId}})
      } else {
        setOpen(true);
      }
    }
  }, [profileData])*/

  return <>
      <UpdateZipModal title={'Enter your zip code'}
                      subtitle={'We will load plan results in your area.'}
                      onClose={(zip?: string, county?: string) => {
                        if (zip && county) {
                          history.push(paths.category + zip + '/' + county);
                          setStoredUser({...getStoredUser(), countyName: county})
                        }
                      }}
                      open={open}
                      disableBackdropClick={true}
                      disableSidePadding={true}
                      buttonLabel={'Continue'} />
    </>;
}
