import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import React from "react";

export function PlanSectionTitle({title}: { title: string }) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box justifyContent={"center"} width={1}
         textAlign={"center"}
         p={'8px 0'}
         mb={'16px'}
         borderRadius={'10px'}
         position={'sticky'} top={phone ? 117 : 304}
         boxShadow={'0px 2px 6px 2px rgba(0, 0, 0, 0.04)'}
         bgcolor={'white'} zIndex={1}>
      <Typography variant={"h1"} color={'textPrimary'}><strong>{title}</strong></Typography>
    </Box>
  )
}
