import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import React from "react";

export function PlanSectionTitle({title}: { title: string }) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box
      mb={'16px'}
      zIndex={1}
      position={'sticky'}
      pt={'10px'}
      bgcolor={'#f5f7f6'}
      top={phone ? 146 : 255}>
      <Box justifyContent={"center"} width={1}
           textAlign={"center"}
           p={'8px 0'}
           borderRadius={'10px'}
           boxShadow={'0px 2px 6px 2px rgba(0, 0, 0, 0.04)'}
           bgcolor={'white'}>
        <Typography variant={"h1"} color={'textPrimary'}><strong>{title}</strong></Typography>
      </Box>
    </Box>
  )
}
