import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import usePlan, {usePlanLazy} from "../DetailedPlan/hooks/usePlan";
import {getQuoteId} from "../../../shared/QuoteId";
import Preloader from "../../../shared/Preloader";
import {config} from "../config/config";
import LoginWidget from "../PlanList/LoginWidget";
import PlanListSideContent from "../PlanList/PlanListSideContent";
import Layout from "../../../shared/Layout";
import {useHistory} from 'react-router-dom';
import {Box, Button, Card, Divider, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import ShortPlanCard from "../../../shared/components/ShortPlanCard";
import AscendButton from "../../../shared/components/AscendButton";
import useSaveEnrollment from "../../../Enrollment/hooks/useSaveEnrollment";
import useSaveRequest from "../../../Questionnaire/hooks/useSaveRequest";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";
import {PlanYear} from "../../../types";
import {GetPlansYear} from "../../shared/hooks/GetPlansYear";
import {getCId} from "../../../shared/utils";

export function Completed(props: RouteComponentProps<{zip: string, countyName: string, planId: string}>) {
  const {planId, zip, countyName} = props.match.params;
  const history = useHistory();
  const [getPlan, {data}] = usePlanLazy();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [save] = useSaveEnrollment();
  const [saveQuote] = useSaveRequest();

  React.useEffect(() => {
    if ((window as any).track) {
      (window as any).track('Enrollment - start booking', {source: 'V1', planId});
    }
  }, []);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          countyName,
          zip,
          quoteId: getQuoteId()
        }
      })
      if (getQuoteId()) {
        save({
          variables: {
            data: {
              bidId: planId,
              quoteId: getQuoteId(),
              planYear: GetPlansYear() === '2022' ? PlanYear.Year2022: PlanYear.Year2023,
            }
          }
        }).then(data => {
          setStoredUser({
            ...getStoredUser(),
            enrollmentId: data?.data?.saveEnrollment,
          })
        });
      } else {
        saveQuote({variables: {
            data: {
              county: countyName,
              zip: zip,
              form: 'SEARCH_RESULTS',
              cId: getCId(),
              medicarePlanType: 'MA',
              planYear: GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023,
            }
          }}).then(res => save({
          variables: {
            data: {
              bidId: planId,
              quoteId: res.data?.saveMedicareQuote,
              planYear: GetPlansYear() === '2022' ? PlanYear.Year2022 : PlanYear.Year2023,
            }
          }}).then(data => {
          setStoredUser({
            ...getStoredUser(),
            enrollmentId: data?.data?.saveEnrollment,
          })
        }));
      }
    }
  }, [planId, zip, getPlan])

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])

  if (!plan) {
    return <Preloader />
  }

  const onClick = () => {
    document.location.href = config.userFlowUrl + 'speak-to-advisor'
  }

  return (
    <Layout sideHeaderContent={config.enableAuth === 'true' ? <LoginWidget/> : <></>} SideComponent={PlanListSideContent}>
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={'100%'} maxWidth={800} mt={'40px'}>
          <Typography className={'bold fs-40 lh-50'}>Congratulations on selecting a new Medicare Advantage plan!</Typography>
          <Typography className={'bold fs-22 lh-27 dark-green mb-20 mt-12'}>Here is the plan you selected:</Typography>
          <ShortPlanCard plan={plan}/>
          <Box flexDirection={{xs: 'column', md: 'row'}} alignItems={{xs: 'center', mg: 'flex-start'}} className={'flex mt-30 mb-40'}>
            <img className={(!phone ? 'mr-20 ' : '') + 'w-110 h-110'} src={'/img/speak-to-advisor.svg'} />
            <div className={phone ? 'mt-20' : ''}>
              <Typography className={'fs-26 bold lh-32 mb-8'}>Connect with your dedicated CoverRight advisor to complete your enrollment.</Typography>
              <Typography className={'fs-20 lh-25'}>Your advisor will collect some additional information and confirm your eligibility before submitting your application.</Typography>
            </div>
          </Box>
          <Divider className={'mb-35'} />

          <Box display={'flex'} width={'100%'} flexDirection={{xs: 'column-reverse', md: 'row'}} alignItems={{xs: 'center', md: 'flex-start'}} justifyContent={'space-between'}>
            <div>
              <AscendButton variant={'contained'} color={'secondary'} className={'regular w-150'} onClick={() => {
                history.goBack()
              }}>Back</AscendButton>
            </div>
            <div>
              <AscendButton className={'gtm-step-event w-250'} onClick={onClick}
                                                variant={'contained'} color={'primary'} type="submit">Connect now</AscendButton>
            </div>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default Completed;
