import * as _ from 'lodash';

const COMPARISON_KEY = 'MEDIGAP_COMPARISON';

export function addToCompare(bidId: string) {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  const stored: string[] = storedValue ? JSON.parse(storedValue) : [];
  removeFromCompare(bidId);
  if (stored.length === 3) {
    stored.splice(2, 1);
  }
  sessionStorage.setItem(COMPARISON_KEY, JSON.stringify([...stored, bidId]));
}

export function removeFromCompare(bidId: string) {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  if (!storedValue) {
    return;
  }
  const stored: string[] = JSON.parse(storedValue);
  sessionStorage.setItem(COMPARISON_KEY, JSON.stringify(_.without(stored, bidId)));
}

export function getCompareList(): string[] {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  return storedValue ? JSON.parse(storedValue) : [];
}
