import React from 'react';
import {Box, Divider, Grid, Typography} from "@material-ui/core";

export function JsxPlanDetail({title, content, className}: { title: string | JSX.Element, content: any, className?: string }) {
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} my={"20px"} className={className}>
        <Box minWidth={'50%'} width={'50%'} className={'pl-20'}>
          <Typography variant={"h5"}>
            {title}
          </Typography>
        </Box>
        <Box display={"flex"} className={'pr-20'}>
          {content}
        </Box>
      </Box>
    </>
  )
}
